/*globals Bloodhound mobileViewport*/
// initialise twitter typeahead
class SiteSearchAutocomplet {
    // TODO: link needs to be changed to client FB instance

    init() {
        this.liveFunnelbackUrl = 'https://funnelback-staging01.squiz.net/s/suggest.json?collection=vanilla-package&show=10&sort=0&alpha=.5&fmt=json++&profile=_default&';
        this.fbSuggestSource = new Bloodhound({
            datumTokenizer: Bloodhound.tokenizers.whitespace,
            queryTokenizer: Bloodhound.tokenizers.whitespace,
            remote:         {
                url:      this.liveFunnelbackUrl + 'partial_query=%QUERY',
                wildcard: '%QUERY'
            }
        });
        this.attachTypeahead();
    }

    attachTypeahead() {
        $('.typeahead').typeahead(null, {
            name:    'fb-suggestions',
            display: 'disp',
            source:  this.fbSuggestSource
        }).on('typeahead:selected', function () {
            $(this).closest('form').submit();
        });
        Array.from(document.querySelectorAll('.tt-hint')).forEach(elem => {
            elem.setAttribute('title', 'suggestions');
        });
    }
    destroy() {
        $('.typeahead').typeahead('destroy');
    }
};

const autocomplete = new SiteSearchAutocomplet;
autocomplete.init();

// mobileViewport defined in global.js
mobileViewport.addListener(function(mq) {
    if(mq.matches) {
        autocomplete.destroy();
        autocomplete.attachTypeahead();
    } else {
        autocomplete.destroy();
        autocomplete.attachTypeahead();
    }
});
