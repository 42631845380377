(function () {
    'use strict';

    /* POLYFILLS */
    Number.isInteger = Number.isInteger || function(value) {
        return typeof value === 'number' && 
          isFinite(value) && 
          Math.floor(value) === value;
    };

    if (typeof Object.assign != 'function') {
        // Must be writable: true, enumerable: false, configurable: true
        Object.defineProperty(Object, "assign", {
          value: function assign(target, varArgs) { // .length of function is 2
            'use strict';
            if (target == null) { // TypeError if undefined or null
              throw new TypeError('Cannot convert undefined or null to object');
            }
      
            var to = Object(target);
      
            for (var index = 1; index < arguments.length; index++) {
              var nextSource = arguments[index];
      
              if (nextSource != null) { // Skip over if undefined or null
                for (var nextKey in nextSource) {
                  // Avoid bugs when hasOwnProperty is shadowed
                  if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                    to[nextKey] = nextSource[nextKey];
                  }
                }
              }
            }
            return to;
          },
          writable: true,
          configurable: true
        });
      }

    /* END POLYFILLS */

    if (document.getElementById('planning-scheme__map')) {
        function getUrlVariable(variable) {
               var query = window.location.search.substring(1);
               var vars = query.split("&");
               for (var i=0;i<vars.length;i++) {
                       var pair = vars[i].split("=");
                       if(pair[0] == variable){return pair[1];}
               }
               return false;
        }

        var getZoom = 7;
        var center = {
            lat: -36.779592,
            lng: 145.266724
        };

        if(document.location.href.indexOf('zoom') !== -1) {
            if(Number.isInteger(parseInt(getUrlVariable('zoom'), 10))) {
                getZoom = parseInt(getUrlVariable('zoom'), 10);
            } else {
                getZoom = 8;
            }
            center = {
                lat: -37.779592,
                lng: 145.266724
            };
            if(getZoom === 10) {
                center = {
                    lat: -37.779592,
                    lng: 144.986724
                };
            }
        } else if(document.getElementById('planning-scheme__map').hasAttribute('data-map-zoom')) {
            getZoom = document.getElementById('planning-scheme__map').getAttribute('data-map-zoom');
        }

        const planningSchemeMap = {
            mapWrapper:   document.getElementById('planning-scheme__map') ? document.getElementById('planning-scheme__map') : '',
            australiaMap: null,
            mapDataUrl:   document.getElementById('planning-scheme__map') ? document.getElementById('planning-scheme__map').getAttribute('data-map-url') : '',
            councilAreas: [],
            mapOptions:   {
                scrollwheel: false,
                center: center,
                zoom:             Number(getZoom),
                mapTypeId:        typeof google !== 'undefined' ? google.maps.MapTypeId.ROADMAP : null,
                disableDefaultUI: true,
                zoomControl:      true
            },
            areaOptions: {
                hover: {
                    fillOpacity: .4,
                    fillColor:   '#f7981d',
                    strokeColor: '#f7981d'
                },
                active: {
                    // fillColor:   '#855487',
                    // fillOpacity: .9,
                    // strokeColor: '#855487'
                },
                defaults: {
                    fillColor:   '#663367',
                    fillOpacity: .2,
                    strokeColor: '#663367'
                },
                init: {
                    fillColor:     '#663367',
                    fillOpacity:   .2,
                    strokeColor:   '#663367',
                    strokeOpacity: .9,
                    strokeWeight:  2
                }
            },
            markerLabelOptions: {
                draggable:   false,
                raiseOnDrag: false,
                labelAnchor: typeof google !== 'undefined' ? new google.maps.Point(0,40) : null,
                labelClass:  'map__markers',
                labelStyle:  {
                    opacity: 1
                },
                icon:    ' ',
                visible: false,
                active:  false
            },
            queryStr: document.getElementById('planning-scheme__map') 
                ? document.getElementById('planning-scheme__map').getAttribute('data-map-query') 
                    ? document.getElementById('planning-scheme__map').getAttribute('data-map-query')
                    : 'f.Scheme%7CplanningSchemeName'
                : '',
            actionUrl: document.getElementById('planning-scheme__map') 
                ? document.getElementById('planning-scheme__map').getAttribute('data-map-actionUrl')
                    ? document.getElementById('planning-scheme__map').getAttribute('data-map-actionUrl')
                    : ''
                : '',
            queryVal: 'undefined',


            initialize: function() {

                this.australiaMap = new google.maps.Map(this.mapWrapper, this.mapOptions);

                this.googlePrototypeGetBounds();

                this.getMapData();
            },

            getMapData: function() {
                $.getJSON(planningSchemeMap.mapDataUrl).then(function(response) {
                    return response;
                }).then(function(jsonData) {
                    planningSchemeMap.areas(jsonData);
                });
            },

            areas: function(fetchData) {
                const mapData = fetchData;

                mapData.map(area => {

                    const coordinates = area.boundaries.map(coordinate => {
                        return new google.maps.LatLng(coordinate[1], coordinate[0]);
                    });
                    
                    const victoriaLocalGovernmentAreas = new google.maps.Polygon(Object.assign({
                        paths: coordinates
                    }, planningSchemeMap.areaOptions.init));

                    // create council name Label
                    const markerLabel = new MarkerWithLabel(Object.assign({

                        position:     victoriaLocalGovernmentAreas.getBounds().getCenter(),
                        map:          planningSchemeMap.australiaMap,
                        labelContent: area.name.toLocaleLowerCase()

                    }, planningSchemeMap.markerLabelOptions));

                    // create popup marker (google "Info Window")
                    var popupMarker = new google.maps.InfoWindow({
                        position:     victoriaLocalGovernmentAreas.getBounds().getCenter(),
                        map:          planningSchemeMap.australiaMap,
                        content: planningSchemeMap.drawPopup(area.data)
                    });
                    popupMarker.active = false;
                    popupMarker.close(planningSchemeMap.australiaMap);

                    planningSchemeMap.councilAreas.push({area: victoriaLocalGovernmentAreas, popupMarker: popupMarker});

                    //draw Goverment Areas
                    victoriaLocalGovernmentAreas.setMap(planningSchemeMap.australiaMap);

                    //add event listeners
                    planningSchemeMap.eventListeners(victoriaLocalGovernmentAreas, markerLabel, popupMarker);

                });
            },

            eventListeners: function(area, label, popupMarker) {

                google.maps.event.addListener(area, 'mouseover', function() {
                    planningSchemeMap.mouseoverHandler(this, label);
                });

                google.maps.event.addListener(area, 'mouseout', function() {
                    planningSchemeMap.mouseoutHandler(this, label);
                });

                google.maps.event.addListener(area, 'click', function() {
                    planningSchemeMap.clickHandler(this, popupMarker);
                });

                google.maps.event.addListener(label, 'click', function() {
                    planningSchemeMap.clickHandler(area, popupMarker);
                });
            },

            mouseoverHandler: function(area, label) {
                if(!label.active) {
                    area.setOptions(planningSchemeMap.areaOptions.hover);
                    label.setVisible(true);
                }
            },

            mouseoutHandler: function(area, label) {
                if(!label.active) {
                    area.setOptions(planningSchemeMap.areaOptions.defaults);
                    label.setVisible(false); 
                }
            },

            hidePopups: function() {
                planningSchemeMap.councilAreas.forEach(function(area){
                    planningSchemeMap.hidePopup(area.popupMarker);
                });
            },

            hidePopup(popupLabel) {
                popupLabel.active = false;
                popupLabel.close();
            },

            clickHandler: function(area, label) {
                label.active === true ? label.active = false : label.active = true;
                if (label.active) {
                    planningSchemeMap.hidePopups();
                    area.setOptions(planningSchemeMap.areaOptions.active);
                    label.open(planningSchemeMap.australiaMap);
                } else {
                    planningSchemeMap.hidePopups();
                    area.setOptions(planningSchemeMap.areaOptions.defaults);
                    label.close(planningSchemeMap.australiaMap);
                }
            },

            activeArea: function(area, label) {
                if (planningSchemeMap.queryVal.toLocaleLowerCase() === label.labelContent) {
                    label.setVisible(true);
                    label.active = true;
                    area.setOptions(planningSchemeMap.areaOptions.active);
                }
            },

            toTitleCase: function(str) {
                return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
            },

            googlePrototypeGetBounds: function() {
                google.maps.Polygon.prototype.getBounds = function () {
                    const bounds = new google.maps.LatLngBounds();
                    this.getPath().forEach(function (el) { 
                        bounds.extend(el); 
                    });
                    return bounds;
                };
            },

            drawPopup: function(data) {
                var title = data.title ? `<div class="map-popup__title">${data.title}</div>` : '';
                var phone = data.phone ? `<div class="map-popup__contact-numbers">
                                            <p class="map-popup__paragraph--number"><span>Phone:</span>${data.phone}</p>
                                        </div>` : '';
                var contact = data.contact ? `<div class="map-popup__contact-web">
                                                <p class="map-popup__paragraph"><span>Contact:</span><a href="mailto:${data.contact}">${data.contact}</a></p>
                                            </div>` : '';
                var website = data.website ? `<a class="map-popup__btn" href="${data.website}">Website</a>` : '';
                var councillor = data.councillor ? `<a class="map-popup__btn" href="${data.councillor}">Councillors</a>` : '';
                

                return `<div class="map-popup">
                            ${title}
                            <div class="map-popup__content">
                                ${phone}
                                ${contact}
                                <p class="map-popup__btn-wrapper">
                                    ${website}
                                    ${councillor}
                                </p>
                            </div>
                        </div>`;
            }
        };

        planningSchemeMap.initialize();
    }
}());