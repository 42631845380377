/* global mobileViewport Velocity*/
class MegaMenu {
    init() {
        this.content = document.querySelector('.main');
        this.footer = document.querySelector('.main__footer');
        this.items = Array.from(document.querySelectorAll('.mega-menu__item'));
        this.wrapper = document.querySelector('.mega-menu__wrapper');
        this.wrapperHeight = 0;//this.wrapper.getBoundingClientRect().height;
        this.activeLink = null;
        this.activeMenu = null;
        this.resizeTimer = null;

        this.expandMenu = this.expandMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.recalculateMenuHeights = this.recalculateMenuHeights.bind(this);
        const links = document.querySelectorAll('.main-nav__link');
        this.menuHeightsMap = this.createHeightsMap(this.items);
        this.attachMegaMenuListeners(links);
    }

    attachMegaMenuListeners(links) {
        const linksLength = links.length;
        for(let i = 0; i < linksLength; i += 1) {
            links[i].addEventListener('click', this.expandMenu);
        }

        document.querySelector('.mega-menu__close-button').addEventListener('click', this.closeMenu);
        this.content.addEventListener('transitionend', evt => {
            if(evt.target !== this.content) {
                return;
            }
            if(!evt.target.style.transform) {
                this.activeLink.classList.remove('main-nav__link--active');
                this.activeMenu.classList.remove('mega-menu__item--visible');
                this.activeLink = null;
                this.activeMenu = null;
            }
        });
        window.addEventListener('resize', this.recalculateMenuHeights);
    }

    recalculateMenuHeights() {
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(() => {
            this.menuHeightsMap = this.createHeightsMap(this.items);
        }, 500);
    }

    createHeightsMap(menuItems) {
        let heights = {};
        menuItems.map(item => {
            heights[item.id] = item.getBoundingClientRect().height + 72;
        });
        return heights;
    }

    expandMenu(evt) {
        const megaMenuID = evt.target.getAttribute('data-mmid');
        const menu = document.querySelector(`#${megaMenuID}`);

        if(!menu) {
            return;
        }

        evt.preventDefault();
        if(this.activeLink && this.activeLink === evt.target) {
            this.hideMenu();
            return;
        }

        this.setActiveMenu(menu);
        requestAnimationFrame( () => {
            this.setTranslateY(this.content, this.menuHeightsMap[megaMenuID]);
            this.setTranslateY(this.footer, this.menuHeightsMap[megaMenuID]);
            this.wrapper.style.height = `${this.menuHeightsMap[megaMenuID]}px`;
        });

        this.setActiveLink(evt.target);
    }

    setActiveMenu(menu) {
        if(this.activeMenu) {
            // this.activeMenu.style.opacity = 0;
            this.activeMenu.classList.remove('mega-menu__item--visible');
        }

        this.activeMenu = menu;
        // this.activeMenu.style.opacity = 1;
        this.activeMenu.classList.add('mega-menu__item--visible');
    }

    setActiveLink(link) {
        if(this.activeLink) {
            this.activeLink.classList.remove('main-nav__link--active');
        }

        this.activeLink = link;
        this.activeLink.classList.add('main-nav__link--active');
    }

    closeMenu(evt) {
        evt.preventDefault();
        this.hideMenu();
    }

    hideMenu() {
        this.clearTranslate(this.content);
        this.clearTranslate(this.footer);
    }

    setTranslateY(element, value) {
        if (Modernizr.csstransforms3d) {
            element.style.transform = `translate3d(0, ${value}px, 0)`;
        } else {
            element.style.transform = `translateY(${value}px)`;
        }
    }

    clearTranslate(element) {
        element.style.transform = '';
    }

    clearStyles() {
        this.wrapper.removeAttribute('style');
        this.hideMenu();
    }
}



//mobileMenu
class MobileControls {
    init() {
        this.wrapper = document.querySelector('.mega-menu__wrapper');
        this.menuTriggerBtn = document.querySelector('.main-menu__btn');
        this.searchTriggerBtn = document.querySelector('.header-search__mobile-btn');
        this.formWrapper = document.querySelector('.header-search__form');
        this.overlay = document.querySelector('.menu-overlay');

        //active
        this.activeMenu = null;
        this.activeSubNav= null;

        this.toggleMenu = this.toggleMenu.bind(this);
        this.onOverlayClick = this.onOverlayClick.bind(this);
        this.attachMegaMenuListeners();
        this.attachSearchListener();
    }

    hideActiveSubNav() {
        this.hide(this.activeSubNav);
        this.activeSubNav.classList.remove('is-active');
        this.activeSubNav.parentNode.classList.remove('is-expanded');
        this.activeSubNav = null;
    }

    handleNavClick(currentSubnav) {
        const isActive = currentSubnav === this.activeMenu ? true : false;

        if(this.activeSubNav) {
            this.hideActiveSubNav();
        }

        if(isActive) {
            //hide current
            this.hide(currentSubnav);
            currentSubnav.classList.remove('is-active');
            this.activeMenu = null;
        } else {
            //hide selected -> show current
            if(this.activeMenu) {
                this.hide(this.activeMenu);
                this.activeMenu.classList.remove('is-active');
                this.activeMenu.parentNode.classList.remove('is-expanded');
            }
            currentSubnav.classList.add('is-active');
            this.activeMenu = currentSubnav;
            this.show(currentSubnav);
        }
    }

    handleSubNavClick(currentSubnav) {
        const isActive = currentSubnav === this.activeSubNav ? true : false;
        if(isActive) {
            //hide current
            this.hide(currentSubnav);
            currentSubnav.classList.remove('is-active');
            this.activeSubNav = null;
        } else {
            //hide all -> show current
            if(this.activeSubNav) {
                this.hideActiveSubNav();
            }
            currentSubnav.classList.add('is-active');
            this.activeSubNav = currentSubnav;
            this.show(currentSubnav);
        }
    }

    attachMegaMenuListeners() {
        //toggle mega menu button
        this.menuTriggerBtn.addEventListener('click', this.toggleMenu);
        this.overlay.addEventListener('click', this.onOverlayClick);

        //lvl 1 variables
        const itemsLevel1 = Array.from(document.querySelectorAll('.mega-menu__item')),
            linkLevel1 = '.mega-menu__feature-link .menu-arrow',
            subnavClass = '.mega-menu__navs-wrapper';

        //lvl 2 variables
        const itemsLevel2 = Array.from(document.querySelectorAll('.mega-menu__list-item.lvl-2')),
            linkLevel2 = '.mega-menu__sub-nav-title .menu-arrow',
            subnavClassLevel2 = '.lvl-2 .mega-menu__list';

        //lvl1
        itemsLevel1.map(item => {
            const currentSubnav = item.querySelector(subnavClass);
            const currentLink = item.querySelector(linkLevel1);

            if(!currentSubnav) {
                currentLink.classList.add('hidden');
            }

            currentLink.addEventListener('click', (e) => {
                e.preventDefault();

                if(!currentSubnav) {
                    return;
                }

                //ignore if currently transitioning
                if(document.querySelector('.velocity-animating')) {
                    return;
                }

                this.handleNavClick(currentSubnav);
                item.classList.toggle('is-expanded');
            });
        });

        //lvl2
        itemsLevel2.map(item => {
            const currentSubnav = item.querySelector(subnavClassLevel2);
            const currentLink = item.querySelector(linkLevel2);

            if(!currentSubnav) {
                currentLink.classList.add('hidden');
            }

            currentLink.addEventListener('click', (e) => {
                e.preventDefault();

                if(!currentSubnav) {
                    return;
                }

                //ignore if currently transitioning
                if(document.querySelector('.velocity-animating')) {
                    return;
                }

                this.handleSubNavClick(currentSubnav);
                item.classList.toggle('is-expanded');
            });
        });
    }

    attachSearchListener() {
        this.searchTriggerBtn.addEventListener('click', () => this.toggleForm());
    }

    hideActiveNavs() {
        if(this.activeMenu) {
            this.hide(this.activeMenu);

            if(this.activeSubNav) {
                this.hide(this.activeSubNav);
            }
        }
    }

    clearActiveNavs(hideFlag) {
        if(hideFlag){
            this.hideActiveNavs();
        }

        if(this.activeMenu) {
            this.activeMenu.classList.remove('is-active');
            this.activeMenu = null;

            if(this.activeSubNav) {
                this.activeSubNav.classList.remove('is-active');
                this.activeSubNav = null;
            }
        }
    }

    onOverlayClick(evt) {
        if (evt.target == this.overlay) {
            if (this.menuTriggerBtn.classList.contains('is-active')) {
                this.toggleMenu();
            } else {
                this.toggleForm();
            }
        }
    }

    toggleMenu(callback) {
        //ignore if currently transitioning
        if(this.wrapper.classList.contains('velocity-animating'))
            return;

        if(this.menuTriggerBtn.classList.contains('is-active')) {

            let expandedItems = Array.from(this.wrapper.querySelectorAll('.is-expanded'));

            //hide mega menu
            this.hide(this.wrapper);
            this.overlay.classList.remove('show');

            //colapse all subnavs
            this.clearActiveNavs(true);
            if(!expandedItems) {
                return;
            }

            expandedItems.map(item => item.classList.remove('is-expanded'));

        } else {

            //hide search if opened
            if (this.searchTriggerBtn.classList.contains('is-active')) {
                this.toggleForm();
            }

            this.show(this.wrapper);
            this.overlay.classList.add('show');
        }

        this.menuTriggerBtn.classList.toggle('is-active')
    }

    toggleForm() {
        if(this.formWrapper.classList.contains('velocity-animating'))
            return;

        if(this.searchTriggerBtn.classList.contains('is-active')) {

            this.hide(this.formWrapper);
            this.overlay.classList.remove('show');

        } else {

            if(this.menuTriggerBtn.classList.contains('is-active')) {
                this.toggleMenu();
            }

            this.show(this.formWrapper);
            this.overlay.classList.add('show')
        }

        this.searchTriggerBtn.classList.toggle('is-active');
    }

    show(elem) {
        Velocity(elem, 'slideDown');
    }

    hide(elem) {
        Velocity(elem, 'slideUp');
    }

    clearStyles() {
        this.wrapper.removeAttribute('style');
        this.formWrapper.removeAttribute('style');
        this.menuTriggerBtn.classList.remove('is-active');
        this.searchTriggerBtn.classList.remove('is-active');
        this.overlay.classList.remove('show');
        [...this.wrapper.querySelectorAll('*[style]')].forEach(item => item.removeAttribute('style') );
        [...this.wrapper.querySelectorAll('.is-expanded')].forEach(item => item.classList.remove('is-expanded'));
        this.clearActiveNavs();
    }
}

const megaMenu = new MegaMenu();
const mobileControls = new MobileControls();

mobileControls.init();
megaMenu.init();

// mobileViewport defined in global.js
mobileViewport.addListener(function(mq) {
    if(mq.matches) {
        megaMenu.clearStyles();
    } else {
        mobileControls.clearStyles();
    }
});


// Change font size functionality
if($('.functionality__font-size').length > 0) {
    var initialHtmlFontSize = $('html').css('font-size');
     $('.functionality__magnify-font').on('click', () => {
        $('html').css({fontSize: parseFloat($('html').css('font-size')) < 22 ? (parseFloat($('html').css('font-size')) + 2)+'px' : '22px' });
     });
     $('.functionality__lessen-font').on('click', () => {
        $('html').css({fontSize: parseFloat($('html').css('font-size')) > 10 ? (parseFloat($('html').css('font-size')) - 2)+'px' : '10px' });
     });
     $('.functionality__reset').on('click', () => {
        $('html').css({fontSize: initialHtmlFontSize});
     });
}