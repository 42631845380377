/*global Velocity*/
class Accordion {
    constructor(settings = {}) {
        this.settings         = settings;
        this.target           = settings.target || '.accordion';
        this.targetBlock      = settings.targetBlock || '.accordion__block';
        this.targetHeading    = settings.targetHeading || '.accordion__heading';
        this.targetContent    = settings.targetContent || '.accordion__content';
        this.allowMultiple    = (undefined === settings.allowMultiple ? false : settings.allowMultiple);
        this.showDefault      = (undefined === settings.showDefault ? true : settings.showDefault);

        this.dataAttr = {
            initShow: 'data-initindex',
            multiple: 'data-multiple',
            showDefault: 'data-showdefault'
        }
    }

    addClickEvents(blockHeading, blockContent, block, accordionBlocks, allowMultiple) {
        blockHeading.addEventListener('click', evt => {
            const isActive            = JSON.parse(block.getAttribute('aria-expanded'));
            const previousActiveBlock = [...accordionBlocks].filter(block => JSON.parse(block.getAttribute('aria-expanded')))[0];

            evt.preventDefault();

            if (isActive) {
                Velocity(blockContent, 'slideUp');
                block.classList.remove('active');
                block.setAttribute('aria-expanded', false);

            } else {
                Velocity(blockContent, 'slideDown');
                block.classList.add('active');
                block.setAttribute('aria-expanded', true);

                if (previousActiveBlock && !allowMultiple) {
                    const previousActiveBlockContent = previousActiveBlock.querySelector(this.targetContent);

                    Velocity(previousActiveBlockContent, 'slideUp');
                    previousActiveBlock.classList.remove('active');
                    previousActiveBlock.setAttribute('aria-expanded', false);
                }
            }
        });
    }

    transformBlocks(accordionBlocks, allowMultiple) {
        [...accordionBlocks].forEach( (block, blockIndex) => {
            const blockHeading = block.querySelector(this.targetHeading);
            const blockContent = block.querySelector(this.targetContent);

            block.setAttribute('aria-expanded', false);
            blockHeading.setAttribute('role', 'heading');
            blockContent.setAttribute('role', 'region');

            this.addClickEvents(blockHeading, blockContent, block, accordionBlocks, allowMultiple);
        });
    }

    showDefaultBlock(accordion, accordionBlocks) {
        const dataInitIndex   = accordion.getAttribute(this.dataAttr.initShow);
        const showInitIndex   = (dataInitIndex) ? parseInt(dataInitIndex) - 1 : 0;
        const showInitContent = accordion.querySelectorAll(this.targetContent)[showInitIndex];

        Velocity(showInitContent, 'slideDown');
        accordionBlocks[showInitIndex].classList.add('active');
        accordionBlocks[showInitIndex].setAttribute('aria-expanded', true);
    }

    init() {
        const accordions = document.querySelectorAll(this.target);
        [...accordions].forEach( (accordion, accordionIndex) => {
            const componentIndex       = accordionIndex + 1;
            const componentId          = `${this.target.slice(1)}-${componentIndex}`;
            const accordionBlocks      = accordion.querySelectorAll(this.targetBlock);

            const dataShowDefaultValue = accordion.getAttribute(this.dataAttr.showDefault) ? accordion.getAttribute(this.dataAttr.showDefault) : true;
            const dataShowDefaultCheck = (dataShowDefaultValue) ? JSON.parse(dataShowDefaultValue) : true;

            const dataAllowMultipleValue = accordion.getAttribute(this.dataAttr.multiple) ? accordion.getAttribute(this.dataAttr.multiple) : false;
            const dataAllowMultipleCheck = (this.allowMultiple == true && dataAllowMultipleValue == 'false') ? false :
                                           (this.allowMultiple == true && dataAllowMultipleValue == 'true') ? true :
                                           (this.allowMultiple == false && dataAllowMultipleValue == 'true') ? true : false;

            accordion.setAttribute('id', componentId);
            this.transformBlocks(accordionBlocks, dataAllowMultipleCheck);

            if (this.showDefault && dataShowDefaultCheck)
                this.showDefaultBlock(accordion, accordionBlocks);
        });
    }
}

class Tabs {
    constructor(settings = {}) {
        this.target                = settings.target || '.tabs';
        this.targetButtons         = settings.targetButtons || '.tab__button';
        this.targetContents        = settings.targetContents || '.tab__content';
        this.accordionWrapperClass = settings.accordionWrapperClass || 'mobile-tabs__accordion';
        this.accordionBlockClass   = settings.accordionBlockClass || 'accordion__block';
        this.accordionHeadingClass = settings.accordionHeadingClass || 'accordion__heading';
        this.accordionContentClass = settings.accordionContentClass || 'accordion__content';
        this.showMobileAccordion   = settings.showMobileAccordion;

        this.dataAttr = {
            initShow: 'data-initindex',
            showAccordion: 'data-accordion'
        }
    }

    addClickEvents(component, tabLink, tabLinks) {
        const linkTarget    = tabLink.getAttribute('aria-controls');
        const contentTarget = component.querySelector(`#${linkTarget}`);

        try {
            if (contentTarget.length) throw 0
        } catch(err) {
            console.error(`Can't switch tab because #${linkTarget} doesn't exist.\nIts probably because there is more tab buttons than content areas`);
        }

        tabLink.addEventListener('click', evt => {
            const isActive = contentTarget.classList.contains('active');
            if (isActive) return;

            const previousActive         = [...tabLinks].filter(link => link.classList.contains('active'))[0];
            const previousActiveContent  = previousActive.getAttribute('aria-controls');
            const previousActiveSelector = component.querySelector(`#${previousActiveContent}`);

            evt.preventDefault();

            if (previousActive) {
                previousActive.classList.remove('active');
                previousActive.setAttribute('aria-selected', false);

                previousActiveSelector.classList.remove('active');
                previousActiveSelector.setAttribute('aria-hidden', true);
            }

            tabLink.classList.add('active');
            tabLink.setAttribute('aria-selected', true);

            contentTarget.classList.add('active');
            contentTarget.setAttribute('aria-hidden', false);
        });
    }

    createMobileAccordion(tabComponent, tabComponentId) {
        const mobileHeadings = tabComponent.querySelectorAll(this.targetButtons);
        const mobileContents = tabComponent.querySelectorAll(this.targetContents);

        try {
            if (mobileHeadings.length > mobileContents.length) throw true;
            if (mobileHeadings.length < mobileContents.length) throw true;
        } catch(err) {
            console.error(`Mobile Accordion can't be initialized for #${tabComponentId} because amount of tab buttons and content areas aren't equal.
            \nAmount of tab buttons: ${mobileHeadings.length} || Amount of content areas: ${mobileContents.length}`);
            return;
        }

        let accordionContainer = document.createElement('div');
            accordionContainer.classList.add(this.accordionWrapperClass);

        const accordionHTML = [...mobileHeadings].map( (heading, index) => {
            return `<div class="${this.accordionBlockClass}">
                        <button class="${this.accordionHeadingClass}">${heading.innerHTML}</button>
                        <div class="${this.accordionContentClass}">${mobileContents[index].innerHTML}</div>
                    </div>`
        }).join('');

        accordionContainer.innerHTML = accordionHTML;
        tabComponent.parentNode.insertBefore(
            accordionContainer,
            tabComponent.nextSibling
        );
    }

    defaultActiveTab(tabComponent, tabComponentButtons) {
        const dataInitIndex = tabComponent.getAttribute(this.dataAttr.initShow);
        const initIndex     = (dataInitIndex) ? parseInt(dataInitIndex) - 1 : 0;
        const initLink      = tabComponentButtons[initIndex] || tabComponentButtons[0];
        const initTarget    = initLink.getAttribute('aria-controls');
        const initContent   = tabComponent.querySelector(`#${initTarget}`);

        initLink.classList.add('active');
        initLink.setAttribute('aria-selected', true);

        initContent.classList.add('active');
        initContent.setAttribute('aria-hidden', false);
    }

    transformTabsContents(tabComponentContents, tabComponentId) {
        [...tabComponentContents].forEach( (content, contentIndex) => {
            const tabContentId = `tabs${tabComponentId}-${contentIndex + 1}__content`;
            const tabButtonId  = `tabs${tabComponentId}-${contentIndex + 1}__button`;

            content.setAttribute('id', tabContentId);
            content.setAttribute('aria-hidden', true);
            content.setAttribute('aria-labelledby', tabButtonId);
            content.setAttribute('role', 'tabpanel');
        });
    }

    transformTabsButtons(tabComponent, tabComponentButtons, tabComponentId) {
        [...tabComponentButtons].forEach( (button, buttonIndex) => {
            const tabButtonId  = `tabs${tabComponentId}-${buttonIndex + 1}__button`;
            const tabContentId = `tabs${tabComponentId}-${buttonIndex + 1}__content`;

            button.setAttribute('id', tabButtonId);
            button.setAttribute('aria-controls', tabContentId);
            button.setAttribute('aria-selected', false);
            button.setAttribute('role', 'tab');

            this.addClickEvents(tabComponent, button, tabComponentButtons);
        });
    }

    init() {
        const tabsComponent = document.querySelectorAll(this.target);

        [...tabsComponent].forEach( (tabComponent, tabIndex) => {
            const tabComponentIndex    = tabIndex + 1;
            const tabComponentId       = tabComponentIndex;
            const tabComponentButtons  = tabComponent.querySelectorAll(this.targetButtons);
            const tabComponentContents = tabComponent.querySelectorAll(this.targetContents);
            const tabsButtonsParent    = tabComponentButtons[0].parentNode;
            const dataAccordionValue   = tabComponent.getAttribute(this.dataAttr.showAccordion);
            const showAccordion        = (dataAccordionValue != null) ? JSON.parse(dataAccordionValue) : (this.showMobileAccordion) ? this.showMobileAccordion : true;

            tabComponent.setAttribute('id', tabComponentId);
            tabsButtonsParent.setAttribute('role', 'tablist');

            this.transformTabsContents(tabComponentContents, tabComponentId);
            this.transformTabsButtons(tabComponent, tabComponentButtons, tabComponentId);
            this.defaultActiveTab(tabComponent, tabComponentButtons);

            if (showAccordion)
                this.createMobileAccordion(tabComponent, tabComponentId);
        });
    }
}

const gallerySettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    adaptiveHeight: true,
    asNavFor: '.gallery__nav'
}

const navGallerySettings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    dots: false,
    focusOnSelect: true,
    asNavFor: '.gallery__single'
}

class Gallery {
    constructor(target = '.gallery', settings = gallerySettings, navSettings = navGallerySettings) {
        this.target      = target;
        this.galleries   = document.querySelectorAll(target);
        this.settings    = settings;
        this.navSettings = navGallerySettings;
    }

    init() {
        [...this.galleries].forEach( (gallery, galleryIndex) => {
            const galleryId     = galleryIndex + 1;
            const gallerySingle = gallery.querySelector('.gallery__single');
            const galleryNav    = gallery.querySelector('.gallery__nav');

            gallery.setAttribute('id', `gallery-${galleryId}`);

            // jQuery...
            $(gallerySingle).slick(this.settings);
            $(galleryNav).slick(this.navSettings);
        });
    }
}

class GoogleMaps {
    constructor(mapClass = '.gMap') {
        this.mapClass       = mapClass;
        this.mapContainers  = document.querySelectorAll(mapClass);

        this.dataAttr = {
            mapLat: 'data-lat',
            mapLng: 'data-lng',
            mapZoom: 'data-zoom',
            mapControls: 'data-controls',
            mapMarker: 'data-marker',
            markerLat: 'data-marker-lat',
            markerLng: 'data-marker-lng',
            mapAddress: 'data-address',
        }
    }

    createMarker(mapContainer, componentId, initMap) {
        const markerLat = mapContainer.getAttribute(this.dataAttr.markerLat);
        const markerLng = mapContainer.getAttribute(this.dataAttr.markerLng);

        try {
            if (markerLat == null || markerLng == null) throw true;
            if (markerLat.length == 0 || markerLng.length == 0) throw true;
        } catch(err) {
            console.error(`Can't create marker for  map (#${componentId}) becuase either data-marker-lat or data-marker-lng is not defined or has empty value`);
            return;
        }

        const markerPos = {
            lat: parseFloat(markerLat),
            lng: parseFloat(markerLng)
        };

        const markerIco = testingLocally ? 'mysource_files/pin.png' : '/_designs/images/pin.png';
        const newMaker = new google.maps.Marker({
          position: markerPos,
          map: initMap,
          icon: markerIco
        });
    }

    createMap(mapContainer, componentId) {
        //initialise geocoder service if address found
        const address = mapContainer.getAttribute(this.dataAttr.mapAddress);
        if (address) {
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode( { 'address': address}, (results, status) => {

                if (status == google.maps.GeocoderStatus.OK) {
                    const mapPosLat = results[0].geometry.location.lat();
                    const mapPosLng = results[0].geometry.location.lng();

                    try {
                        if (mapPosLat == null || mapPosLng == null) throw true;
                        if (mapPosLat.length == 0 || mapPosLng.length == 0) throw true;
                    } catch(err) {
                        console.error(`Can't initialize map (#${componentId}) becuase either data-lat or data-lng is not defined or has empty value`);
                        return;
                    }

                    const mapZoom         = mapContainer.getAttribute(this.dataAttr.mapZoom) || 10;
                    const disableControls = (mapContainer.getAttribute(this.dataAttr.mapControls) == 'false') ? true : false;
                    const disableScroll   = (mapContainer.getAttribute(this.dataAttr.mapScroll) == 'false') ? false : true;

                    const addMarkerValue  = mapContainer.getAttribute(this.dataAttr.mapMarker)
                    const addMarkerCheck  = (addMarkerValue !== null) ? JSON.parse(addMarkerValue) : false;

                    const mapSettings = {
                        zoom: parseInt(mapZoom),
                        controls: disableControls,
                        scroll: disableScroll,
                        position: {
                            lat: parseFloat(mapPosLat),
                            lng: parseFloat(mapPosLng)
                        }
                    }

                    const initMap = new google.maps.Map(mapContainer, {
                        zoom: mapSettings.zoom,
                        center: mapSettings.position,
                        disableDefaultUI: mapSettings.controls,
                        scrollwheel: mapSettings.scroll
                    });

                    if (addMarkerCheck) {
                        const markerPos = mapSettings.position;

                        const markerIco = testingLocally ? 'mysource_files/pin.png' : '/_designs/images/pin.png';
                        const newMaker = new google.maps.Marker({
                          position: markerPos,
                          map: initMap,
                          icon: markerIco
                        });
                    }
                } else {
                    console.log(status);
                }
            });
        } else {
            const mapPosLat = mapContainer.getAttribute(this.dataAttr.mapLat);
            const mapPosLng = mapContainer.getAttribute(this.dataAttr.mapLng);

            try {
                if (mapPosLat == null || mapPosLng == null) throw true;
                if (mapPosLat.length == 0 || mapPosLng.length == 0) throw true;
            } catch(err) {
                console.error(`Can't initialize map (#${componentId}) becuase either data-lat or data-lng is not defined or has empty value`);
                return;
            }

            const mapZoom         = mapContainer.getAttribute(this.dataAttr.mapZoom) || 10;
            const disableControls = (mapContainer.getAttribute(this.dataAttr.mapControls) == 'false') ? true : false;
            const disableScroll   = (mapContainer.getAttribute(this.dataAttr.mapScroll) == 'false') ? false : true;

            const addMarkerValue  = mapContainer.getAttribute(this.dataAttr.mapMarker)
            const addMarkerCheck  = (addMarkerValue !== null) ? JSON.parse(addMarkerValue) : false;

            const mapSettings = {
                zoom: parseInt(mapZoom),
                controls: disableControls,
                scroll: disableScroll,
                position: {
                    lat: parseFloat(mapPosLat),
                    lng: parseFloat(mapPosLng)
                }
            }

            const initMap = new google.maps.Map(mapContainer, {
                zoom: mapSettings.zoom,
                center: mapSettings.position,
                disableDefaultUI: mapSettings.controls,
                scrollwheel: mapSettings.scroll
            });

            if (addMarkerCheck)
                this.createMarker(mapContainer, componentId, initMap);
        }
    }

    init() {
        [...this.mapContainers].forEach( (mapContainer, mapIndex) => {
            const componentId = `${this.mapClass.slice(1)}-${mapIndex + 1}`;

            mapContainer.setAttribute('id', componentId);
            this.createMap(mapContainer, componentId);
        });
    }
}

class LhsMobileToggler {
    constructor(settings = {}) {
        this.settings = settings;
        this.container = settings.container || '.lhs__menu';
        this.target = settings.target || '.lhs__menu-list';
        this.trigger  = settings.trigger || '.lhs__menu-toggle';
        this.resetWidth = settings.resetWidth || 1024;
    }

    bindClick(target, trigger, container) {
        trigger.addEventListener('click', evt => {

            if (window.innerWidth >= this.resetWidth) return;

            const isExpanded = JSON.parse(trigger.getAttribute('data-expanded'));
            const slideDir   = isExpanded ? 'slideUp' : 'slideDown';

            evt.preventDefault();

            Velocity(target, slideDir);
            trigger.setAttribute('data-expanded', !isExpanded);
        });
    }

    handleResize(target, trigger, container) {
        window.addEventListener('resize', debounce( () => {
            const isExpanded = JSON.parse(trigger.getAttribute('data-expanded'));

            if (!isExpanded && target.getAttribute('style') == null)
                return;

            if (window.innerWidth >= this.resetWidth) {
                trigger.setAttribute('data-expanded', false);
                target.removeAttribute('style');
            }
        }, 100));
    }

    init() {
        if (document.querySelector(this.container) != null) {
            const container = document.querySelector(this.container);
            const target = container.querySelector(this.target);
            const trigger = container.querySelector(this.trigger);

            trigger.setAttribute('data-expanded', false);
            this.bindClick(target, trigger, container);
            this.handleResize(target, trigger, container);
        }
    }
}

class TableWrapper {
    constructor(target = 'table', inContainer = '.main__content', wrapperClass = 'responsive__table' ) {
        this.container = inContainer;
        this.table    = target;
        this.wrapperClass = wrapperClass;
    }

    init() {
        if (document.querySelector(this.container) != null) {
            const targetConainer = document.querySelector(this.container);
            const contentTables  = targetConainer.querySelectorAll(this.table);

            for (let i = 0; i < contentTables.length; i++) {
                // Check if table don't have overflow container already applied.
                if (contentTables[i].parentNode.getAttribute('class') !== this.wrapperClass) {
                    let wrapper = document.createElement('div');
                    wrapper.setAttribute('class', this.wrapperClass);

                    contentTables[i].parentNode.insertBefore(wrapper, contentTables[i]);
                    wrapper.appendChild(contentTables[i]);
                }
            }
        }
    }
}

class BackToTop {
    constructor(trigger =  '#btn-btt', wrapper = '.btt__wrapper', target = '#page-wrapper') {
        this.wrapper = wrapper;
        this.trigger = trigger;
        this.target  = target;
    }

    bindScrollStop(scrollTarget) {
        const wheelEvt = "onwheel" in document.createElement('div') ? 'wheel' : 'mousewheel';

        [wheelEvt, 'touchmove'].forEach( evtType => {

          window.addEventListener(evtType, function handler(evt) {
            // Trigger once and unbind listener
            evt.currentTarget.removeEventListener(evt.type, handler);
            Velocity(scrollTarget, 'stop');
          })

        });
    }

    bindClick(nodeWrapper, nodeTrigger, scrollTarget) {
        nodeTrigger.addEventListener('click', evt => {
            evt.preventDefault();
            Velocity(scrollTarget, 'scroll', {duration: 800});

            this.bindScrollStop(scrollTarget);
        });
    }

    init() {
        if (document.querySelector(this.wrapper) != null) {
            const nodeWrapper = document.querySelector(this.wrapper);
            const nodeTrigger = nodeWrapper.querySelector(this.trigger);
            const scrollTarget = document.querySelector(this.target);

            this.bindClick(nodeWrapper, nodeTrigger, scrollTarget);
        }
    }
}

class ImageCaption {
    constructor(target = 'img', container = '.main__content') {
        this.images    = target;
        this.container = container;
    }

    applyImageCaption(imgTargets) {
        [...imgTargets].forEach(image => {
            // If image don't have title or have expection class do nothing.
            if (image.getAttribute('title') == null || image.classList.contains('no-figure'))
                return;

                let imageFigure = document.createElement('figure');
                let imageWrapper = document.createElement('div');
                imageFigure.classList.add('figure__image');
                imageWrapper.classList.add('image__wrapper');
                image.parentNode.insertBefore(imageFigure, image.nextSibling);
                imageFigure.appendChild(imageWrapper);
                imageWrapper.appendChild(image);

                // Add image classes to figure element e.g float classes, and remove classes from image
                if (image.classList.length) {
                    imageFigure.classList.add(image.classList.value);
                    image.removeAttribute('class');
                }

                // Add caption to figure
                imageFigure.insertAdjacentHTML('beforeend', `
                    <figcaption>${image.getAttribute('title')}</figcaption>
                `);

                // Removie title from image
                image.removeAttribute('title');
        });
    }

    init() {
        if (document.querySelector(this.container) != null) {
            const containerTarget = document.querySelector(this.container);
            const imgTargets      = containerTarget.querySelectorAll(this.images);

            this.applyImageCaption(imgTargets);
        }
    }
}

class TableOfContent {
    constructor(container = '.main__content', target = 'h1:not(.page__heading), h2:not(.page__heading), h3:not(.toc__heading)') {
        this.targets    = target;
        this.toc        = '.toc__box';
        this.tocHeading = '.toc__heading';
        this.container  = container;
    }

    getHeaders(selector, scope) {
        let ret = [];
        const target = document.querySelector(scope);
        const elems  = target.querySelectorAll(selector);

        [...elems].forEach(elem => ret.push(elem));
        return ret;
    }

    setAttrs(src, target, index) {
        const content = src.textContent;
        const pre = `toc-${index}`;

        target.textContent = src.textContent;

        let id = src.id || pre;
        id = encodeURIComponent(id);

        src.id = id;
        target.href = '#' + id;
    }

    buildTOC() {
        let ret = document.createElement('ul');
        ret.classList.add('toc__list', 'no-content-styles');
        let wrapper = ret;

        this.getHeaders(this.targets, this.container).forEach( (header, headerIndex) => {
            let li  = document.createElement('li');
            let a   = document.createElement('a');

            li.classList.add('toc__item');
            a.classList.add('toc__link');

            this.setAttrs(header, a, headerIndex);
            wrapper.appendChild(li).appendChild(a);
        });

        document.querySelector(this.toc).innerHTML += ret.outerHTML;
    }

    addToggler() {
        const toc = document.querySelector(this.toc);
        const tocHeading = toc.querySelector(this.tocHeading);

        let toggler = document.createElement('button');
        toggler.classList.add('toc__toggler');

        if (window.innerWidth >= 768) {
            toggler.textContent = 'Hide';
            toggler.setAttribute('data-expanded', true);
        } else {
            toggler.textContent = 'Show';
            toggler.setAttribute('data-expanded', false);
        }

        tocHeading.appendChild(toggler);
        this.bindTogglerListener(toc, toggler);
    }

    bindTogglerListener(toc, toggler) {
        const tocList = toc.querySelector('.toc__list');

        toggler.addEventListener('click', () => {
            const isExpanded = JSON.parse(toggler.getAttribute('data-expanded'));
            const slideType = isExpanded ? 'slideUp' : 'slideDown';
            const testToShow = isExpanded ? 'Show' : 'Hide';

            Velocity(tocList, slideType);
            toggler.setAttribute('data-expanded', !isExpanded);
            toggler.textContent = testToShow;
        });
    }

    init() {
        let tocElem = document.querySelector(this.toc);
        if (tocElem != null && this.getHeaders(this.targets, this.container).length > 0) {
            this.buildTOC();
            this.addToggler();
            tocElem.classList.remove('hidden');
        } else if (tocElem) {
            tocElem.remove();
        }
    }
}


/* ==== ==== */
const toc = new TableOfContent();
toc.init();


/* ==== FIGURE CAPTION FOR IMAGES WITH TITLE ==== */
const imgCaption = new ImageCaption();
imgCaption.init();

/* ==== BACK TO TOP HANDLER ==== */
const btt = new BackToTop();
btt.init();

/* ==== MOBILE LHS MENU TOGGLER ==== */
const menuToggler = new LhsMobileToggler();
menuToggler.init();

/* ==== CONTENT TABS ==== */
const contentTabs = new Tabs();
contentTabs.init();

/* ==== MOBILE ACCORDION FOR TABS ==== */
const mobileTabsAccordion = new Accordion({
    target: '.mobile-tabs__accordion',
    showDefault: false
});
mobileTabsAccordion.init();

/* ==== CONTENT ACCORDION ==== */
const contentAccordion = new Accordion({
    allowMultiple: true
});
contentAccordion.init();

/* ==== CONTENT GALLERY ==== */
const contentGallery = new Gallery();
contentGallery.init();

/* ==== CONTENT GOOGLE MAPS ==== */
const gMaps = new GoogleMaps();
gMaps.init();

/* ==== CONTENT TABLE WRAP ==== */
const responsiveTable = new TableWrapper();
responsiveTable.init();
