function carouselToggler(carousel, toggler) {
    var $carousel  = $(carousel),
        $toggler   = $carousel.find(toggler);

    if ( $toggler.hasClass('carousel-paused') ) {
        $carousel.slick('slickPlay');
        $toggler.removeClass('carousel-paused');
    } else {
        $carousel.slick('slickPause');
        $toggler.addClass('carousel-paused');
    }
}

var landscapeSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    cssEase: 'linear',
    dots: true,
    arrows: false,
    speed: 600,
    slide: '.landscape-carousel__item',
    dotsClass: 'landscape-carousel__dots'
}

$('.landscape-carousel').on('init', function() {
    var $this         = $(this),
        $wrapper      = $this.parent('.landscape-carousel__wrapper'),
        $dotsClass    = `.${landscapeSettings.dotsClass}`;

    if ($wrapper.find($dotsClass).length) {

        $this.find($dotsClass)
            .wrap('<div class="landscape-carousel__controls" />');

        var $controlsWrapper = $this.find('.landscape-carousel__controls'),
            $toggler = `
                <button class="landscape-carousel__toggler">
                    <span class="visuallyhidden">Play/Pause</span>
                </button>`;

        $($toggler).appendTo($controlsWrapper);

        $this.find('.landscape-carousel__toggler')
            .on('click', () => carouselToggler(this, '.landscape-carousel__toggler') );
    }

}).slick(landscapeSettings);
