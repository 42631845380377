/*global jQuery*/
/**
 * {{name}}
 * Global JS
 *
 * version: {{version}}
 * file:    {{file}}
 * author:  Squiz Australia
 * change log:
 *     {{date}} - First revision
 */

/*
 * Table of Contents
 *
 * - Global
 * - Modules
{{toc}}
 */
'use strict';
/*
--------------------
Global
--------------------
*/
const testingLocally = location.host.indexOf(':') !== -1;

const applyObjectfit = () => {
    const $html = $('html');
    if (!$html.hasClass('object-fit')) {
        $('.js-objectfit').each(function() {
            let $container = $(this),
            imgUrl = $container.find('img').prop('src');

            if (imgUrl) {
                $container.css('backgroundImage', 'url(' + imgUrl + ')').addClass('compat-object-fit');
            }
        });
    }
};
applyObjectfit();

// External Links
// Set exclusions - just add the classes you want to ignore
const excludes = [
    '.share',
    '.gallery-slider a',
    '.carousel-control',
    '.no-ext-ic',
    '.is-video',
    '.main__footer a',
    '.popup__video',
    '.popup__image',
    '.rhs__related a',
    'a[href^="mailto"]',
    'a[href^="tel"]'
];

//if any link is external add a rel attribute with external value
$('a[href!=""]').not(excludes.join(',')).filter(function() {
    return (window.location.hostname != this.hostname);
}).prop('rel', 'external');

$(document).ready(function(){
  //add target blank to all rel="external elements"
  $('a[rel="external"]').attr('target', '_blank');
});

window.Velocity = window.Velocity || (window.jQuery || window.Zepto || window).Velocity;

//  Declare JS Enabled.
if (document.body.classList.contains('no-js')) {
    document.body.classList.remove('no-js');
    document.body.classList.add('js-enabled');
}

if (Modernizr.mq('screen and (max-width: 63.9375em)')) {
    document.body.classList.add('is-mobile');
}

const mobileViewport = window.matchMedia('screen and (max-width: 63.9375em)');
mobileViewport.addListener(function(mq) {
    if(mq.matches) {
        document.body.classList.add('is-mobile');
    } else {
        document.body.classList.remove('is-mobile');
    }
});

$('.popup__image').magnificPopup({
    type:            'image',
    fixedContentPos: false
});

$('.popup__video').magnificPopup({
    type:            'iframe',
    fixedContentPos: false
});


//SVG Polyfill init
if(window.svg4everybody) {
    window.svg4everybody();
}

/*
--------------------
Modules
--------------------
*/
