class FieldsetAccordion {
    constructor(container = '.rhs-filters.facets-filters', target = 'fieldset.form__group', trigger = 'legend', targetContent = '.form-fieldset__content') {
        this.container = container;
        this.target    = target;
        this.trigger   = trigger;
        this.content   = targetContent;

        this.addClickEvent = this.addClickEvent.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleResize(target, targetContent) {
        window.addEventListener('resize', debounce( () => {
            if (window.innerWidth >= 1024) {
                if (target.getAttribute('data-expanded') == null && targetContent.getAttribute('style') == null)
                    return;

                target.removeAttribute('data-expanded');
                targetContent.removeAttribute('style');

            } else {
                target.setAttribute('data-expanded', false);
            }
        }, 250));
    }

    addClickEvent(targets) {
        [...targets].forEach(target => {
            if (window.innerWidth < 1024)
                target.setAttribute('data-expanded', false);

            const trigger = target.querySelector(this.trigger);
            const targetContent = target.querySelector(this.content);

            trigger.addEventListener('click', () => {
                if (window.innerWidth >= 1024) return;

                const isExpanded = JSON.parse(target.getAttribute('data-expanded'));
                const slideType = isExpanded ? 'slideUp' : 'slideDown';

                target.setAttribute('data-expanded', !isExpanded);
                Velocity(targetContent, slideType);
            });

            this.handleResize(target, targetContent);
        });
    }

    init() {
        if (document.querySelector(this.container) != null) {
            const container = document.querySelector(this.container);
            const targets   = container.querySelectorAll(this.target);

            this.addClickEvent(targets);
        }
    }
}

const filtersAccordion = new FieldsetAccordion();
filtersAccordion.init();

class FormFiltersAccordion {
    constructor(container = '.rhs-filters.form-filters', target = '.rhs-filters__container', trigger = '.rhs-filters__heading', targetContent = 'form') {
        this.container = container;
        this.target    = target;
        this.trigger   = trigger;
        this.content   = targetContent;

        this.addClickEvent = this.addClickEvent.bind(this);
        this.handleResize = this.handleResize.bind(this);
    }

    handleResize(target, targetContent) {
        window.addEventListener('resize', debounce( () => {
            if (window.innerWidth >= 1024) {
                if (target.getAttribute('data-expanded') == null && targetContent.getAttribute('style') == null)
                    return;

                target.removeAttribute('data-expanded');
                targetContent.removeAttribute('style');

            } else {
                target.setAttribute('data-expanded', false);
            }
        }, 250));
    }

    addClickEvent(targets) {
        [...targets].forEach(target => {
            if (window.innerWidth < 1024)
                target.setAttribute('data-expanded', false);

            const trigger = target.querySelector(this.trigger);
            const targetContent = target.querySelector(this.content);

            trigger.addEventListener('click', () => {
                if (window.innerWidth >= 1024) return;

                const isExpanded = JSON.parse(target.getAttribute('data-expanded'));
                const slideType = isExpanded ? 'slideUp' : 'slideDown';

                target.setAttribute('data-expanded', !isExpanded);
                Velocity(targetContent, slideType);
            });

            this.handleResize(target, targetContent);
        });
    }

    init() {
        if (document.querySelector(this.container) != null) {
            const container = document.querySelector(this.container);
            const targets   = container.querySelectorAll(this.target);

            this.addClickEvent(targets);
        }
    }
}

const formFiltersAccordion = new FormFiltersAccordion();
formFiltersAccordion.init();

class DatePicker {
    addClearButtons(dateInputs) {
        [...dateInputs].forEach( dateInput => {
            let wrapper = document.createElement('span');
            wrapper.classList.add('datepicker__wrap');

            dateInput.parentNode.insertBefore(wrapper, dateInput.nextSibling);
            wrapper.appendChild(dateInput);

            wrapper.insertAdjacentHTML('beforeend', '<button type="button" class="datepicker__clear">×</button>');

            const clearButton = wrapper.querySelector('.datepicker__clear');

            if (dateInput.value.length != 0)
                clearButton.style.display = 'block';

            this.bindClearButtons(dateInput, clearButton);
        });
    }

    bindClearButtons(inputTarget, clearButton) {
        clearButton.addEventListener('click', () => {
            inputTarget.value = null;
            clearButton.style.display = 'none';
        });
    }

    init() {
        // jQuery
        const datePicketTarget = $('.datepicker');
        datePicketTarget.prop('readonly', true);

        datePicketTarget.datepicker({
            altFormat:  'dd-mm-yy',
            dateFormat: 'dd-mm-yy',
            onSelect: (inputValue, dataObj) => {
                const dataInput = dataObj.input[0];
                const inputWrapper = dataInput.parentNode;
                const clearButton = inputWrapper.querySelector('.datepicker__clear');
                clearButton.style.display = 'block';
            }
        });

        this.addClearButtons(datePicketTarget);
    }
}

const dPicker = new DatePicker();
dPicker.init();
